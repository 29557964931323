import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const UrlHandlerApiService = {
  BASE_URL: "",

  async getBaseUrl() {
    if (this.BASE_URL) return this.BASE_URL;

    Vue.use(VueAxios, axios);
    const resp = await Vue.axios.get(`api/url/get-base-uri`);
    this.BASE_URL = resp.data;
    return this.BASE_URL;
  }
};

export default UrlHandlerApiService;
