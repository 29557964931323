import VueAxios from "vue-axios";
import axios from "axios";
import Vue from "vue";
import { DISABLE_TOKEN_INTERCEPTOR } from "@/common/interceptor-helpers";

/**
 * Service to call HTTP request via Axios
 */
const FirebaseService = {
  URL: "",

  async init() {
    try {
      Vue.use(VueAxios, axios);

      const headers = {
        "req-context": JSON.stringify({
          [DISABLE_TOKEN_INTERCEPTOR]: true
        })
      };

      const resp = await Vue.axios.get(`api/url/get-base-uri`, { headers });
      this.URL = resp.data;
    } catch (error) {
      throw new Error(`[KT] FirebaseService ${error}`);
    }
  },
  connect(obj) {
    const headers = {
      Authorization: "Bearer " + obj.token,
      "req-context": JSON.stringify({
        [DISABLE_TOKEN_INTERCEPTOR]: true
      })
    };

    const body = {
      deviceId: obj.deviceId
    };

    // WE USE SKIP AUTH CHECK TO PASS OUR OWN BEARER TOKEN
    return Vue.axios
      .post(this.URL + `api/user/v4/connect`, body, {
        headers
      })
      .catch(error => {
        // console.log(error);
        throw new Error(`[KT] FirebaseService ${error}`);
      });
  },
  verifyUser: token => {
    return Vue.axios
      .get("api/verify", {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      .catch(error => {
        // console.log(error);
        throw new Error(`[KT] FirebaseService ${error}`);
      });
  }
};

export default FirebaseService;
