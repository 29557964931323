import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const EmptyPage = () => import("@/view/pages/plugins/Empty.vue");
const OobleeBannerDefinitionUpdate = () =>
  import("@/view/pages/ooblee-banner-definition-update.vue");
const OobleeBannerInstanceUpdate = () => import("@/view/pages/ooblee-banner-instance-update.vue");
const OobleeBannerInstanceInsert = () => import("@/view/pages/ooblee-banner-instance-insert.vue");
const PromotionUpdate = () => import("@/view/pages/promotions-update.vue");
const RecommendedByUpdate = () => import("@/view/pages/recommended-by-update.vue");
const SuggestedForYouUpdate = () => import("@/view/pages/suggested-for-you-update.vue");
const NewsUpdate = () => import("@/view/pages/news-update.vue");
const SponsorUpdate = () => import("@/view/pages/sponsors-update.vue");
const SectorUpdate = () => import("@/view/pages/sector-update.vue");
const RecommendedCategoryUpdate = () => import("@/view/pages/recommended-categories-update.vue");
const RecommendedCategoryInsert = () => import("@/view/pages/recommended-categories-insert.vue");
const BroadcastMessageUpdate = () => import("@/view/pages/broadcast-message-update.vue");
const TitleDefinitionUpdate = () => import("@/view/pages/title-definition-update.vue");
const ShopTags = () => import("@/view/pages/shop-tags.vue");
const ShopPostTags = () => import("@/view/pages/shop-post-tags.vue");
const ShopWalletGift = () => import("@/view/pages/shop-wallet-gift.vue");
const ShopCityShopping = () => import("@/view/pages/shop-city-shopping.vue");
const UserShops = () => import("@/view/pages/user-shops.vue");
const Users = () => import("@/view/pages/Users.vue");
const ShopPromotionSubscription = () => import("@/view/pages/shop-promotion-subscription.vue");
const ShopUpdate = () => import("@/view/pages/shop-update.vue");
const ShopPostUpdate = () => import("@/view/pages/shop-post-update.vue");
const AddNewShopPost = () => import("@/view/pages/add-new-shop-post/add-new-shop-post.vue");
const ShopSubscriptions = () => import("@/view/pages/shop-subscriptions.vue");
const ShopCategories = () => import("@/view/pages/categories/ShopCategories.vue");
const MainFeedCategories = () => import("@/view/pages/categories/mainFeedCategories.vue");
const MainFeedCategoriesAdd = () => import("@/view/pages/categories/mainFeedCategoriesAdd.vue");
const ShopCategoriesReorder = () => import("@/view/pages/categories/ShopCategoriesReorder.vue");
const ShopCategoryUpdate = () => import("@/view/pages/shop-category-update.vue");
const Subsriptions = () => import("@/view/pages/Subscriptions.vue");
const AdminPanelUsers = () => import("@/view/pages/AdminPanelUsers.vue");
const AdminPanelUserInsert = () => import("@/view/pages/admin-panel-user-insert.vue");
const AdminPanelUserEdit = () => import("@/view/pages/admin-panel-user-edit.vue");
const NewCollection = () => import("@/view/pages/NewCollection.vue");
const NewCollectionUpdate = () => import("@/view/pages/new-collection-update.vue");
const ShopProductImporter = () => import("@/view/pages/import-csv-shop-products.vue");
const DonationGoals = () => import("@/view/pages/DonationGoals.vue");
const DonationGoalUpdate = () => import("@/view/pages/donation-goal-update.vue");
const GlobalGoals = () => import("@/view/pages/GlobalGoals.vue");
const GlobalGoalUpdate = () => import("@/view/pages/global-goal-update.vue");
const Memberships = () => import("@/view/pages/Memberships.vue");
const ShopInformation = () => import("@/view/pages/ShopInformation.vue");
const Packages = () => import("@/view/pages/Packages.vue");
const PackagePermissions = () => import("@/view/pages/PackagePermissions.vue");
const PackageUpdate = () => import("@/view/pages/package-update.vue");
const PackagePermissionUpdate = () => import("@/view/pages/package-permissions-update.vue");
const SuperMemberships = () => import("@/view/pages/SuperMemberships.vue");
const SuperMembershipUpdate = () => import("@/view/pages/super-membership-update.vue");
const ShopRedeems = () => import("@/view/pages/shop-redeems.vue");
const InvoiceViewer = () => import("@/view/pages/invoice-viewer.vue");
const ShoppingEvents = () => import("@/view/pages/ShoppingEvents.vue");
const ShoppingEventUpdate = () => import("@/view/pages/shopping-event-update");
const ShoppingEventPosts = () => import("@/view/pages/ShoppingEventPosts");
const ShoppingEventPostUpdate = () => import("@/view/pages/shopping-event-post-update");
const Legal = () => import("@/view/pages/Legal.vue");
const LegalUpdate = () => import("@/view/pages/legal-update.vue");
const Vouchers = () => import("@/view/pages/voucher/Vouchers.vue");
const VoucherLimitations = () => import("@/view/pages/voucher/voucher-limitations.vue");
const VoucherLimitationsShop = () => import("@/view/pages/voucher/voucher-limitations-shop.vue");
const VoucherUpdate = () => import("@/view/pages/voucher/voucher-update.vue");
const PromoCodes = () => import("@/view/pages/PromoCodes.vue");
const PromoCodeUpdate = () => import("@/view/pages/promo-code-update.vue");
const PromoCodeBulkInsert = () => import("@/view/pages/promo-code-bulk-insert.vue");
const WalletApproval = () => import("@/view/pages/WalletApproval.vue");
const Algorithm = () => import("@/view/pages/Algorithm.vue");
const AlgorithmUpdate = () => import("@/view/pages/algorithm-update.vue");
const SwfOffer = () => import("@/view/pages/swf-offer/swf-offer.vue");
const SwfOfferUpdate = () => import("@/view/pages/swf-offer/swf-offer-update.vue");
const SwfConfiguration = () => import("@/view/pages/SwfConfiguration.vue");
const Scheduler = () => import("@/view/pages/scheduler/scheduler-list/scheduler-list.vue");
const SchedulerCities = () =>
  import("@/view/pages/scheduler/scheduler-cities/scheduler-cities.vue");
const Influencers = () => import("@/view/pages/influencers/influencers.vue");
const CustomerReferralPrograms = () =>
  import("@/view/pages/customer-referral/customer-referral-programs.vue");
const CustomerReferralInvitations = () =>
  import("@/view/pages/customer-referral/customer-referral-invitations.vue");
const MessagesList = () => import("@/view/pages/messages/messages-list.vue");
const GroupsList = () => import("@/view/pages/messages/groups-list.vue");
const MessagesForm = () => import("@/view/pages/messages/messages-form.vue");
const CoinsPage = () => import("@/view/pages/campaigns/coins/coins.vue");
const WheelOfFortune = () => import("@/view/pages/campaigns/wheel-of-fortune/wheel-of-fortune.vue");
const TreatCampaign = () => import("@/view/pages/campaigns/treat-campaign/treat-campaign.vue");
const WelcomeScreenList = () =>
  import("@/view/pages/campaigns/welcome-screen/welcome-screen-list/welcome-screen-list.vue");
const WelcomeScreenForm = () =>
  import("@/view/pages/campaigns/welcome-screen/welcome-screen-form/welcome-screen-form.vue");
const GeneralSettings = () => import("@/view/pages/general-settings/general-settings.vue");
const GiftCardVoucher = () =>
  import(
    "@/view/pages/campaigns/gift-card-voucher/gift-card-voucher-list/gift-card-voucher-list.vue"
  );
const PaymentsList = () => import("@/view/pages/payments/payments-list/payments-list.vue");

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/promotions/title-definitions/new",
          name: "TitleDefinitionCreate",
          component: TitleDefinitionUpdate
        },
        {
          path: "/promotions/title-definitions/:titleDefinitionId/edit",
          name: "TitleDefinitionEdit",
          component: TitleDefinitionUpdate
        },
        {
          path: "/banners-new/banner-definitions/new",
          name: "BannerDefinitionCreate",
          component: OobleeBannerDefinitionUpdate
        },
        {
          path: "/banners-new/banner-definitions/:bannerDefinitionId/edit",
          name: "BannerDefinitionEdit",
          component: OobleeBannerDefinitionUpdate
        },
        {
          path: "/banners-new/banner-instances/new",
          name: "BannerInstanceCreate",
          component: OobleeBannerInstanceInsert
        },
        {
          path: "/banners-new/banner-instances/:bannerInstanceId/edit",
          name: "BannerInstanceEdit",
          component: OobleeBannerInstanceUpdate
        },
        {
          path: "/shop-category/new",
          name: "ShopCategoryCreate",
          component: ShopCategoryUpdate
        },
        {
          path: "/shop-category/:shopCategoryId/edit",
          name: "ShopCategoryEdit",
          component: ShopCategoryUpdate
        },
        {
          path: "/promotions/promotion-instances/new",
          name: "PromotionCreate",
          component: PromotionUpdate
        },
        {
          path: "/promotions/promotion-instances/:promotionId/edit",
          name: "PromotionEdit",
          component: PromotionUpdate
        },
        {
          path: "/recommended-by/new",
          name: "RecommendedByCreate",
          component: RecommendedByUpdate
        },
        {
          path: "/recommended-by/:recommendedById/edit",
          name: "RecommendedByEdit",
          component: RecommendedByUpdate
        },
        {
          path: "/suggested-for-you/new",
          name: "SuggestedForYouCreate",
          component: SuggestedForYouUpdate
        },
        {
          path: "/suggested-for-you/:suggestedForYouId/edit",
          name: "SuggestedForYouEdit",
          component: SuggestedForYouUpdate
        },
        {
          path: "/new-collection/new",
          name: "NewCollectionCreate",
          component: NewCollectionUpdate
        },
        {
          path: "/new-collection/:newCollectionId/edit",
          name: "NewCollectionEdit",
          component: NewCollectionUpdate
        },
        {
          path: "/news/new",
          name: "NewsCreate",
          component: NewsUpdate
        },
        {
          path: "/news/:newsId/edit",
          name: "NewsEdit",
          component: NewsUpdate
        },
        {
          path: "/sponsors/new",
          name: "SponsorCreate",
          component: SponsorUpdate
        },
        {
          path: "/sponsors/:sponsorId/edit",
          name: "SponsorEdit",
          component: SponsorUpdate
        },
        {
          path: "/sectors/new",
          name: "SectorCreate",
          component: SectorUpdate
        },
        {
          path: "/sectors/:sectorId/edit",
          name: "SectorEdit",
          component: SectorUpdate
        },
        {
          path: "/recommended-categories/new",
          name: "RecommendedCategoryCreate",
          component: RecommendedCategoryInsert
        },
        {
          path: "/recommended-categories/:recommendedCategoryId/edit",
          name: "RecommendedCategoryEdit",
          component: RecommendedCategoryUpdate
        },
        {
          path: "/broadcast-messages/new",
          name: "BroadcastMessageCreate",
          component: BroadcastMessageUpdate
        },
        {
          path: "/message-groups",
          name: "message-groups",
          component: GroupsList
        },
        {
          path: "/broadcast-messages/:broadcastMessageId/edit",
          name: "BroadcastMessageEdit",
          component: BroadcastMessageUpdate
        },
        {
          path: "/admin-panel-user/new",
          name: "AdminPanelUserCreate",
          component: AdminPanelUserInsert
        },
        {
          path: "/admin-panel-user/:userId/edit",
          name: "AdminPanelUserEdit",
          component: AdminPanelUserEdit
        },
        {
          path: "/users",
          name: "users",
          //    component: () => import("@/view/pages/Users.vue"),
          component: EmptyPage,
          children: [
            {
              path: "all-users",
              name: "all-users",
              component: Users
            },
            {
              path: "ap-users",
              name: "AdminPanelUsers",
              component: AdminPanelUsers
            },
            {
              path: "influencers",
              name: "influencers",
              component: Influencers
            }
          ]
        },
        /*       {
          path: "/ap-users",
          name: "AdminPanelUsers",
          component: AdminPanelUsers
        },*/
        {
          path: "/shop-products-importer",
          name: "Products Importer",
          component: ShopProductImporter
        },
        {
          path: "/shops",
          name: "shops",
          component: () => import("@/view/pages/Shops.vue")
        },
        {
          path: "/shops/:shopId/tags",
          name: "ShopTags",
          component: ShopTags
        },
        {
          path: "/shops/:shopId/wallet-gift",
          name: "ShopWalletGift",
          component: ShopWalletGift
        },
        {
          path: "/shops/:shopId/shop-city-shopping",
          name: "ShopCityShopping",
          component: ShopCityShopping
        },
        {
          path: "/users/:userId/user-shops",
          name: "UserShops",
          component: UserShops
        },
        {
          path: "/shops/:shopId/promotion-subscription",
          name: "ShopPromotionSubscription",
          component: ShopPromotionSubscription
        },
        {
          path: "/shops/:shopId/edit",
          name: "ShopEdit",
          component: ShopUpdate
        },
        {
          path: "/shop-posts/:shopPostId/edit",
          name: "ShopPostEdit",
          component: ShopPostUpdate
        },
        {
          path: "/shop-posts/add-new-shop-post",
          name: "add-new-shop-post",
          component: AddNewShopPost
        },
        {
          path: "/shops/:shopId/subscriptions",
          name: "ShopSubscriptions",
          component: ShopSubscriptions
        },
        {
          path: "/shop-posts",
          name: "shop-posts",
          component: () => import("@/view/pages/ShopPosts.vue")
        },
        {
          path: "/shop-posts/:shopPostId/tags",
          name: "ShopPostTags",
          component: ShopPostTags
        },
        {
          path: "/shop-advertised-posts",
          name: "shop-advertised-posts",
          component: () => import("@/view/pages/ShopAdvertisedPosts.vue")
        },
        {
          path: "/orders",
          name: "orders",
          component: () => import("@/view/pages/Orders.vue")
        },
        {
          path: "/recommended-categories",
          name: "recommended-categories",
          component: () => import("@/view/pages/categories/RecommendedCategories.vue")
        },
        {
          path: "/ooblee-categories",
          name: "ooblee-categories",
          component: ShopCategories
        },
        {
          path: "/shop-categories-reorder",
          name: "shop-categories-reorder",
          component: ShopCategoriesReorder
        },
        {
          path: "/main-feed-categories",
          name: "main-feed-categories",
          component: MainFeedCategories
        },
        {
          path: "/main-feed-categories/main-feed-categories-add",
          name: "main-feed-categories-add",
          component: MainFeedCategoriesAdd
        },
        {
          path: "/sectors",
          name: "sectors",
          component: () => import("@/view/pages/Sectors.vue")
        },
        {
          path: "/broadcast-messages",
          name: "broadcast-messages",
          component: () => import("@/view/pages/BroadcastMessages.vue")
        },
        {
          path: "/subscriptions",
          name: "subscriptions",
          component: Subsriptions
        },
        {
          path: "/suggested-for-you",
          name: "suggested-for-you",
          component: () => import("@/view/pages/SuggestedForYou.vue")
        },
        {
          path: "/new-collection",
          name: "new-collection",
          component: NewCollection
        },
        {
          path: "/recommended-by",
          name: "recommended-by",
          component: () => import("@/view/pages/RecommendedBy.vue")
        },
        {
          path: "/blogs",
          name: "blogs",
          component: () => import("@/view/pages/Sponsors.vue")
        },
        {
          path: "/news",
          name: "news",
          component: () => import("@/view/pages/News.vue")
        },
        {
          path: "/social-posts",
          name: "social-posts",
          component: () => import("@/view/pages/SocialPosts.vue")
        },
        {
          path: "/banners",
          name: "banners",
          component: () => import("@/view/pages/Banners.vue")
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/category",
          name: "category",
          component: () => import("@/view/pages/Category.vue")
        },
        {
          path: "/collection",
          name: "collection",
          component: () => import("@/view/pages/Collection.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/Profile.vue"),
          props: route => ({ profileId: route.query.id })
        },
        {
          path: "/banners-new",
          name: "banners-new",
          component: EmptyPage,
          children: [
            {
              path: "banner-definitions",
              name: "banner-definitions",
              component: () => import("@/view/pages/BannerDefinitions.vue")
            },
            {
              path: "banner-instances",
              name: "banner-instances",
              component: () => import("@/view/pages/BannerInstances.vue")
            }
          ]
        },
        {
          path: "/memberships",
          name: "memberships",
          component: EmptyPage,
          children: [
            {
              path: "memberships",
              name: "memberships",
              component: Memberships
            },
            {
              path: "shops-information",
              name: "shops-information",
              component: ShopInformation
            },
            {
              path: "wallet-approval",
              name: "wallet-approval",
              component: WalletApproval
            },
            {
              path: "packages",
              name: "packages",
              component: Packages
            },
            {
              path: "package-permissions",
              name: "package-permissions",
              component: PackagePermissions
            },
            {
              path: "super-memberships",
              name: "super-memberships",
              component: SuperMemberships
            }
          ]
        },
        {
          path: "/group-shopping",
          name: "group-shopping",
          component: EmptyPage,
          children: [
            {
              path: "algorithm",
              name: "algorithm",
              component: Algorithm
            },
            {
              path: "/algorithm/:algorithmId/edit",
              name: "AlgorithmEdit",
              component: AlgorithmUpdate
            },
            {
              path: "/algorithm/new",
              name: "AlgorithmCreate",
              component: AlgorithmUpdate
            },
            {
              path: "swf-configuration",
              name: "swf-configuration",
              component: SwfConfiguration
            },
            {
              path: "swf-offer",
              name: "swf-offer",
              component: SwfOffer
            },
            {
              path: "/swf-offer/edit",
              name: "SwfOfferEdit",
              component: SwfOfferUpdate
            },
            {
              path: "/swf-offer/new",
              name: "SwfOfferCreate",
              component: SwfOfferUpdate
            }
          ]
        },
        {
          path: "/memberships/invoice/:redeemId/:invoiceUrl",
          name: "InvoiceViewer",
          component: InvoiceViewer
        },
        {
          path: "/memberships/shop-redeems/:shopId",
          name: "ShopRedeems",
          component: ShopRedeems
        },
        {
          path: "/memberships/packages/new",
          name: "PackageCreate",
          component: PackageUpdate
        },
        {
          path: "/memberships/packages/:packageId/edit",
          name: "PackageEdit",
          component: PackageUpdate
        },
        {
          path: "/memberships/package-permissions/new",
          name: "PackagePermissionCreate",
          component: PackagePermissionUpdate
        },
        {
          path: "/memberships/package-permissions/:packagePermissionId/edit",
          name: "PackagePermissionEdit",
          component: PackagePermissionUpdate
        },
        {
          path: "/memberships/super-memberships/new",
          name: "SuperMembershipCreate",
          component: SuperMembershipUpdate
        },
        {
          path: "/shopping-events",
          name: "shopping-events",
          component: EmptyPage,
          children: [
            {
              path: "donation-goals",
              name: "donation-goals",
              component: DonationGoals
            },
            {
              path: "shopping-events",
              name: "shopping-events",
              component: ShoppingEvents
            },
            {
              path: "shopping-event-posts",
              name: "shopping-event-posts",
              component: ShoppingEventPosts
            },
            {
              path: "global-goals",
              name: "global-goals",
              component: GlobalGoals
            }
          ]
        },
        {
          path: "/shopping-events/donation-goals/new",
          name: "DonationGoalCreate",
          component: DonationGoalUpdate
        },
        {
          path: "/shopping-events/donation-goals/:donationGoalId/edit",
          name: "DonationGoalEdit",
          component: DonationGoalUpdate
        },
        {
          path: "/shopping-events/shopping-event-posts/:shoppingEventId",
          name: "ShoppingEventPosts",
          component: ShoppingEventPosts
        },
        {
          path: "/shopping-events/shopping-events/new",
          name: "ShoppingEventCreate",
          component: ShoppingEventUpdate
        },
        {
          path: "/shopping-events/shopping-events/:shoppingEventId/edit",
          name: "ShoppingEventEdit",
          component: ShoppingEventUpdate
        },
        {
          path: "/shopping-events/shopping-event-posts/:shoppingEventPostId/edit",
          name: "ShoppingEventPostEdit",
          component: ShoppingEventPostUpdate
        },
        {
          path: "/shopping-events/global-goals/new",
          name: "GlobalGoalCreate",
          component: GlobalGoalUpdate
        },
        {
          path: "/shopping-events/global-goals/:globalGoalId/edit",
          name: "GlobalGoalEdit",
          component: GlobalGoalUpdate
        },
        {
          path: "/legal",
          name: "legal",
          component: Legal
        },
        {
          path: "/vouchers",
          name: "Vouchers",
          component: EmptyPage,
          children: [
            {
              path: "",
              name: "voucher-list",
              component: Vouchers
            },
            {
              path: "/:voucherId/edit",
              name: "VoucherEdit",
              component: VoucherUpdate
            },
            {
              path: "/new",
              name: "VoucherCreate",
              component: VoucherUpdate
            }
          ]
        },
        {
          path: "/voucher-limitations",
          component: EmptyPage,
          children: [
            {
              path: "",
              name: "voucher-limitations",
              component: VoucherLimitations
            },
            {
              path: "shop/:id",
              name: "voucher-limitations-shop",
              component: VoucherLimitationsShop
            }
          ]
        },
        {
          path: "/promo-codes",
          name: "PromoCodes",
          component: PromoCodes
        },
        {
          path: "/promo-codes/:promoCodeId/edit",
          name: "PromoCodeEdit",
          component: PromoCodeUpdate
        },
        {
          path: "/promo-codes/new",
          name: "PromoCodeCreate",
          component: PromoCodeUpdate
        },
        {
          path: "/promo-codes/bulk-insert",
          name: "PromoCodeBulkInsert",
          component: PromoCodeBulkInsert
        },
        {
          path: "/legal/new",
          name: "LegalCreate",
          component: LegalUpdate
        },
        {
          path: "/legal/:legalId/edit",
          name: "LegalEdit",
          component: LegalUpdate
        },
        {
          path: "/promotions",
          name: "promotions",
          component: EmptyPage,
          children: [
            {
              path: "title-definitions",
              name: "title-definitions",
              component: () => import("@/view/pages/Title Definitions.vue")
            },
            {
              path: "promotion-instances",
              name: "promotion-instances",
              component: () => import("@/view/pages/Promotions.vue")
            }
          ]
        },
        {
          path: "/messages",
          component: EmptyPage,
          children: [
            {
              path: "",
              name: "messages-list",
              component: MessagesList
            },
            {
              path: "form",
              name: "messages-form",
              component: MessagesForm
            }
          ]
        },
        {
          path: "/scheduler",
          component: EmptyPage,
          children: [
            {
              path: "",
              name: "scheduler-cities",
              component: SchedulerCities
            },
            {
              path: ":countryId/:cityId",
              name: "scheduler",
              component: Scheduler
            }
          ]
        },
        {
          path: "/referral",
          component: EmptyPage,
          children: [
            {
              path: "programs",
              name: "referal-programs",
              component: CustomerReferralPrograms
            },
            {
              path: "invitations",
              name: "referal-invitations",
              component: CustomerReferralInvitations
            }
          ]
        },
        {
          path: "/coins",
          component: CoinsPage
        },
        {
          path: "/welcome-screen",
          component: EmptyPage,
          children: [
            {
              path: "",
              name: "welcomeList",
              component: WelcomeScreenList
            },
            {
              path: "edit",
              name: "welcomeListEdit",
              component: WelcomeScreenForm
            },
            {
              path: "new",
              name: "welcomeListNew",
              component: WelcomeScreenForm
            }
          ]
        },
        {
          path: "/wheel-of-fortune",
          component: WheelOfFortune
        },
        {
          path: "/treat-campaign",
          component: TreatCampaign
        },
        {
          path: "/general-settings",
          component: GeneralSettings
        },
        {
          path: "/gift-card-voucher",
          component: GiftCardVoucher
        },
        {
          path: "/payments",
          component: EmptyPage,
          children: [
            {
              path: "",
              name: "paymentList",
              component: PaymentsList
            }
          ]
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
