import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import CountryService from "@/core/services/country.service";

import {
  getAuth,
  signInWithEmailAndPassword,
  getIdToken,
  signInWithCustomToken,
  signOut
} from "firebase/auth";
import FirebaseService from "./firebase.service";
import router from "@/router";

// action types

export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const REFRESH_TOKEN = "refreshToken";
export const INIT_USER = "initUser";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  countryId: CountryService.getActiveCountry()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return handleLogin(context, credentials);
  },
  [LOGOUT](context) {
    return context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response?.data?.errors);
        });
    });
  },
  [REFRESH_TOKEN](context) {
    return handleTokenRefresh(context);
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;
    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [INIT_USER](context) {
    return handleUserInit(context);
  }
};

/**
 * Handle firebase login wign in with email and password
 * @param {*} context
 * @param {*} credentials
 */
const handleLogin = async (context, credentials) => {
  try {
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, credentials.email, credentials.password);

    //token
    const user = auth.currentUser;
    const emailAndPassToken = await getIdToken(user);

    //connect
    await FirebaseService.init();
    const connect = await FirebaseService.connect({
      deviceId: "admin-panel",
      token: emailAndPassToken
    });

    const token = connect?.data?.result?.token;

    //custom login to return a valid token
    await signInWithCustomToken(auth, token);
    const updatedUser = auth.currentUser;
    const customToken = await getIdToken(updatedUser);
    const verifyResponse = await FirebaseService.verifyUser(customToken);

    if (updatedUser && customToken && verifyResponse?.data) {
      // format data
      const userLoginData = {
        user: verifyResponse.data,
        token: customToken,
        isAuthenticated: !!verifyResponse.data,
        country: credentials.country
      };

      context.commit(SET_AUTH, userLoginData);
    }
  } catch (error) {
    context.commit(SET_ERROR, error.response.status);
    context.commit(PURGE_AUTH);
  }
};

/**
 * Sign out from firebase and remove user
 * @param {*} state
 */
const handlePurgeAuth = async state => {
  const auth = getAuth();

  if (auth?.currentUser) {
    await signOut(auth);
  }

  state.isAuthenticated = false;
  state.user = {};
  state.errors = {};
  JwtService.destroyToken();
  CountryService.destroyActiveCountry();
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = data?.isAuthenticated;
    state.user = data?.user;
    state.errors = {};
    JwtService.saveToken(data?.token);

    if (data?.country) {
      CountryService.saveActiveCountry(data?.country);
    }
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    return handlePurgeAuth(state);
  }
};

const handleUserInit = async context => {
  try {
    const auth = getAuth();
    await auth.authStateReady();
    const user = auth.currentUser;

    //USER EXISTS
    if (user) {
      const token = await getIdToken(user);
      const response = await FirebaseService.verifyUser(token);

      //USER EXISTS AND REPONDED
      if (user && response?.data) {
        // format data
        const userLoginData = {
          user: response.data,
          token: token,
          isAuthenticated: !!response.data,
          country: state.countryId
        };

        context.commit(SET_AUTH, userLoginData);
      }
    }
  } catch (error) {
    await context.commit(SET_ERROR, error.response?.status);
    await context.commit(PURGE_AUTH);

    if (router && router.currentRoute?.path !== "/" && router.currentRoute?.path !== "/login") {
      router.push({ name: "login", replace: true });
    }
  }
};

const handleTokenRefresh = async context => {
  const auth = getAuth();
  await auth.authStateReady();
  const user = auth.currentUser;

  if (user) {
    const token = await getIdToken(user);
    JwtService.saveToken(token);
  } else {
    await context.commit(PURGE_AUTH);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
