import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import AuthInterceptor from "./auth.interceptor";
import CountryInterceptor from "./country.interceptor";

const GlobalInterceptors = {
  init() {
    Vue.use(VueAxios, axios);

    const request = Vue.axios.interceptors.request;

    // AUTH INTERCEPTOR
    this.appendInterceptor(request, AuthInterceptor);
    this.appendInterceptor(request, CountryInterceptor);
  },
  appendInterceptor(request, interceptor) {
    request.use(
      config => interceptor.getInterceptor(config),
      error => Promise.reject(error)
    );
  }
};

export default GlobalInterceptors;
