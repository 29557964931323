const VersionService = {
  KEY: "app_version",
  checkVersion() {
    const appVersion = localStorage.getItem(this.KEY);
    const currentVersion = process.env.VUE_APP_VERSION;

    return currentVersion === appVersion;
  },
  updateVersion() {
    const currentVersion = process.env.VUE_APP_VERSION;
    localStorage.clear();
    localStorage.setItem(this.KEY, currentVersion);
  }
};

export default VersionService;
