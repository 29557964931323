const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  //return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjI1NSwiZGV2aWNlSWQiOiI4YjIwMzA3NjNlOTM4YTAwIn0.nJQG1627i8uBo7kgbZl3b_OAnyOAkR__Lc5U0ZK-nqw";
   return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
