import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import GlobalInterceptors from "@/core/services/interceptors/global.interceptors";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import Vuetify from "vuetify";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
//import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/datetime-picker";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import VueLogger from "vuejs-logger";
import { locale as me } from "@/core/config/i18n/me";
import { locale as en } from "@/core/config/i18n/en";
import { locale as de } from "@/core/config/i18n/de";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import { firestorePlugin } from "vuefire";
import { initializeApp } from "firebase/app";

// don't forget to import styles
import "tiptap-vuetify/dist/main.css";

const options = {
  isEnabled: true,
  logLevel: "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: false,
  separator: "|",
  showConsoleColors: true
};

Vue.use(VueLogger, options);

// FIREBASE
Vue.use(firestorePlugin);

const firebaseConfig = {
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY
};

initializeApp(firebaseConfig);

// API service init
ApiService.init();
GlobalInterceptors.init();

// Remove this to disable mock API
//MockService.init();

/**
 * It's preferable to first refresh then try to verify user
 */
const precheckUserData = async () => {
  // await store.dispatch(REFRESH_TOKEN);
};

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([precheckUserData()]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.use(Vuetify, {
  lang: {
    locales: { me, en, de },
    current: "de"
  }
});
const vuetify = new Vuetify();
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  // "md" (default), "fa", "mdi"
  iconsGroup: "md" // same as "iconsGroup: iconsGroup"
});
new Vue({
  router,
  store,
  i18n,
  vuetify: new Vuetify({
    lang: {
      t: (key, ...params) => i18n.t(key, params)
    }
  }),
  render: h => h(App)
}).$mount("#app");
