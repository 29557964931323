import CountryService from "../country.service";
import { DISABLE_COUNTRY_INTERCEPTOR } from "@/common/interceptor-helpers";
const CountryInterceptor = {
  getInterceptor(config) {
    // DISABLE IF NEEDED
    if (
      (this.checkIfDisabled(config) && config?.method !== "get") ||
      this.checkUrlIgnoreList(config)
    ) {
      return config;
    }

    const countryId = CountryService.getActiveCountry();
    if (countryId) {
      // IF WE FORCE A COUNTRY ID RETURN CONFIG
      if (config?.params?.countryId) {
        return config;
      }

      if (config && config.params) {
        config.params["countryId"] = countryId;
      } else {
        config["params"] = { countryId };
      }
    }

    return config;
  },

  /* 
  HELPERS
  */

  IGNORE_URLS: ["api/url", "api/verify"],
  checkIfDisabled(config) {
    if (config && config.headers && config.headers["req-context"]) {
      const context = JSON.parse(config.headers["req-context"]);
      return context[DISABLE_COUNTRY_INTERCEPTOR];
    }
  },
  checkUrlIgnoreList(config) {
    return this.IGNORE_URLS.some(url => config.url.startsWith(url));
  }
};

export default CountryInterceptor;
