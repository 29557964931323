<template>
  <v-app>
    <div class="locale-changer"></div>
    <router-view></router-view>
  </v-app>
</template>
<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { INIT_USER } from "@/core/services/store/auth.module";
import VersionService from "@/core/services/version.service";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "MetronicVue",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

    const isValid = VersionService.checkVersion();

    if (!isValid) {
      this.$store.dispatch(LOGOUT);
      VersionService.updateVersion();
    } else {
      this.$store.dispatch(INIT_USER);
    }
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val;
    }
  },
  data() {
    return {
      gettoken() {
        return localStorage.getItem("language") || 0;
      },
      settoken(value) {
        localStorage.setItem("language", value);
      },
      langs: ["me", "en", "de"]
    };
  }
};
</script>
