import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

/**
 * Service to call HTTP request via Axios
 */

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  async queryWithAsync(resource, params) {
    try {
      const resp = await Vue.axios.get(resource, params);
      return resp.data;
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  getFileWithParams(resource, params) {
    let config = {
      responseType: "blob",
      params,
    };
    return Vue.axios.get(resource, config).catch((error) => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  getFile(resource, slug = "") {
    return Vue.axios
      .get(`${resource}/${slug}`, {
        responseType: "blob",
      })
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  },
  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params, {
      headers: { "Content-Type": "application/json-patch+json" },
    });
  },

  patchInf(resource, params) {
    return Vue.axios.patch(`${resource}`, params, {
      headers: { "Content-Type": "application/merge-patch+json" },
    });
  },
  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
