import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Localisation language list
import { locale as en } from '@/core/config/i18n/en';
import { locale as me } from '@/core/config/i18n/me';
import { locale as de } from '@/core/config/i18n/de';

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, me, en, de };

// get current selected language
const lang = localStorage.getItem('language');
Object.defineProperty(Vue.prototype, '$locale', {
  get: function() {
    return i18n.locale;
  },
  set: function(locale) {
    i18n.locale = locale;
  }
});
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
  silentFallbackWarn: true
});

export default i18n;
