import JwtService from "@/core/services/jwt.service";
import { DISABLE_TOKEN_INTERCEPTOR } from "@/common/interceptor-helpers";

const AuthInterceptor = {
  async getInterceptor(config) {
    // DISABLE TOKEN IF NEEDED
    if (this.checkIfDisabled(config)) {
      return config;
    }

    const token = JwtService.getToken();

    // Set the token in the request header
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  checkIfDisabled(config) {
    if (config && config.headers && config.headers["req-context"]) {
      const context = JSON.parse(config.headers["req-context"]);
      return context[DISABLE_TOKEN_INTERCEPTOR];
    }
  }
};

export default AuthInterceptor;
